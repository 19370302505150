<template>
  <form
    class="d-flex ma-4 align-center elevation-1"
    @submit.prevent="submitForm"
  >
    <div class="flex-grow-1 ma-2">
      <p class="ma-0">
        {{ bannerMessage.message }}
      </p>
      <p class="caption ma-0">
        {{ $d(bannerMessage.created, "shortDateTime") }}
      </p>
    </div>

    <v-btn
      type="submit"
      :color="iconColor(button.icon)"
      :loading="isLoading"
      fab
      x-small
      elevation="1"
      class="ma-2"
    >
      <v-icon>{{ button.icon }}</v-icon>
    </v-btn>
  </form>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DeleteBannerMessageForm",
  props: {
    event: {
      type: Object,
      required: true
    },
    bannerMessage: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      button: {
        icon: "mdi-delete"
      }
    };
  },
  methods: {
    ...mapActions({
      deleteSelectedItemBannerMessage: "events/deleteSelectedItemBannerMessage"
    }),
    submitForm() {
      this.isLoading = true;

      const payload = {
        eventId: this.event.id,
        bannerId: this.bannerMessage.id
      };

      this.deleteSelectedItemBannerMessage(payload)
        .then(response => {
          this.isLoading = false;
          this.button.icon = "mdi-check";

          setTimeout(() => {
            this.button.icon = "mdi-delete";
          }, 2000);
        })
        .catch(response => {
          this.isLoading = false;
          this.button.icon = "mdi-close";

          setTimeout(() => {
            this.button.icon = "mdi-delete";
          }, 2000);
        });
    },
    iconColor(icon, defaultColor = "error") {
      switch (String(icon)) {
        case "mdi-check":
          return "success";
        case "mdi-close":
          return "error";
        default:
          return defaultColor;
      }
    }
  }
};
</script>
