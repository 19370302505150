<template>
  <v-dialog
    v-model="show"
    width="600"
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
    @click:outside="closeDialog"
  >
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          {{ $t("events.bannerMessages.title") }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text class="pa-0">
        <DeleteBannerMessageForm
          v-for="bannerMessage in event.bannerMessages"
          :key="bannerMessage.id"
          :event="event"
          :bannerMessage="bannerMessage"
        />

        <CreateBannerMessageForm :event="event" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CreateBannerMessageForm from "@/components/CreateBannerMessageForm";
import DeleteBannerMessageForm from "@/components/DeleteBannerMessageForm";

export default {
  name: "BannerMessagesDialog",
  components: {
    CreateBannerMessageForm,
    DeleteBannerMessageForm
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    event: {
      type: Object,
      required: true
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closed");
    }
  }
};
</script>
