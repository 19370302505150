<template>
  <form
    class="d-flex ma-4 align-center elevation-1"
    @submit.prevent="submitForm"
  >
    <v-text-field
      :label="$t('forms.bannerMessage.message')"
      v-model="$v.form.message.$model"
      :error-messages="messageErrors"
      :counter="150"
      class="ma-2"
    ></v-text-field>

    <v-btn
      type="submit"
      :color="iconColor(button.icon)"
      :loading="isLoading"
      fab
      x-small
      elevation="1"
      class="ma-2"
    >
      <v-icon>{{ button.icon }}</v-icon>
    </v-btn>
  </form>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  name: "CreateBannerMessageForm",
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        message: null
      },
      isLoading: false,
      button: {
        icon: "mdi-plus"
      }
    };
  },
  validations: {
    form: {
      message: {
        required,
        maxLength: maxLength(150)
      }
    }
  },
  computed: {
    messageErrors() {
      const errors = [];
      if (!this.$v.form.message.$dirty) return errors;
      !this.$v.form.message.required &&
        errors.push(
          this.$i18n.t("forms.bannerMessage.errors.message.required")
        );
      !this.$v.form.message.maxLength &&
        errors.push(
          this.$i18n.t("forms.bannerMessage.errors.message.maxLength", {
            length: 150
          })
        );
      return errors;
    }
  },
  methods: {
    ...mapActions({
      createSelectedItemBannerMessage: "events/createSelectedItemBannerMessage"
    }),
    submitForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isLoading = true;

        const payload = {
          eventId: this.event.id,
          data: {
            message: this.form.message
          }
        };

        this.createSelectedItemBannerMessage(payload)
          .then(response => {
            this.isLoading = false;
            this.resetForm();
            this.button.icon = "mdi-check";

            setTimeout(() => {
              this.button.icon = "mdi-plus";
            }, 2000);
          })
          .catch(response => {
            this.isLoading = false;
            this.button.icon = "mdi-close";

            setTimeout(() => {
              this.button.icon = "mdi-plus";
            }, 2000);
          });
      }
    },
    iconColor(icon, defaultColor = "primary") {
      switch (String(icon)) {
        case "mdi-check":
          return "success";
        case "mdi-close":
          return "error";
        default:
          return defaultColor;
      }
    },
    resetForm() {
      this.$v.$reset();
      this.form.message = null;
    }
  }
};
</script>
