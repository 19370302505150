<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-card :loading="eventIsLoading">
      <div v-if="!eventIsLoading">
        <div v-if="event">
          <div
            class="d-flex justify-space-between align-md-end align-start flex-md-row flex-column"
          >
            <v-card-title class="pb-0">{{ event.name }}</v-card-title>
            <div>
              <EventStateChip
                :state="event.state"
                class="ml-4 mr-1 my-1 mx-md-1 my-md-0"
              />
              <EventViewersChip
                :viewers="event.viewers"
                class="mr-4 ml-1 my-1 mx-md-1 my-md-0"
              />
            </div>
            <v-card-title
              v-if="event.start === event.end"
              class="pb-0 ml-md-auto"
            >
              {{ $d(new Date(event.start * 1000), "longDate") }}
            </v-card-title>
            <v-card-title v-else class="pb-0 ml-md-auto">
              {{ $d(new Date(event.start * 1000), "longDateWithoutYear") }} -
              {{ $d(new Date(event.end * 1000), "longDate") }}
            </v-card-title>
          </div>
          <div class="d-flex align-center">
            <v-card-subtitle class="py-0 pr-2">{{
              event.place
            }}</v-card-subtitle>
            <CountryFlag
              :country="getItemById(event.country)"
              :size="15"
            ></CountryFlag>
          </div>
          <div
            class="d-flex mt-4 mb-8 mx-4 justify-space-between align-start flex-md-row flex-column"
          >
            <TheOrganizer :organizer="event.organizer" />

            <v-btn
              v-if="isOwner"
              color="warning"
              small
              :loading="restartIsLoading"
              :width="$vuetify.breakpoint.name === 'xs' ? '100%' : ''"
              class="mt-2 mt-md-4"
              @click="restartEvent"
            >
              {{ $t("events.restartEvent") }}
              <v-icon small right>mdi-replay</v-icon>
            </v-btn>
          </div>

          <div class="d-flex justify-end my-4">
            <MarqueeText
              v-if="event.bannerMessages.length > 0"
              :repeat="10"
              :duration="bannerAnimationSpeed"
              :paused="pauseBanner"
              @mouseenter="pauseBanner = true"
              @mouseleave="pauseBanner = false"
              class="overline white--text grey"
            >
              <span
                v-for="bannerMessage in event.bannerMessages"
                :key="bannerMessage.id"
                class="mx-10"
              >
                {{ bannerMessage.message }}
              </span>
            </MarqueeText>

            <v-tooltip v-if="isOwner" top :open-delay="300">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  small
                  class="ml-2 mr-4"
                  @click="showBannerMessagesDialog = true"
                >
                  <v-icon>mdi-message-cog</v-icon>
                </v-btn>
              </template>
              {{ $t("events.hints.bannerMessages") }}
            </v-tooltip>
          </div>

          <v-tabs v-model="activeTab" icons-and-text show-arrows>
            <v-tab
              v-for="tab in tabs"
              :key="tab.id"
              :to="{ name: tab.route }"
              :exact="tab.id === 'overview'"
            >
              {{ tab.name }}
              <v-icon>{{ tab.icon }}</v-icon>
            </v-tab>

            <v-tab-item
              v-for="tab in tabs"
              :key="tab.id"
              :value="getTabValue(tab.route)"
            ></v-tab-item>
          </v-tabs>

          <keep-alive>
            <router-view
              :event="event"
              @arming="handleArming"
              @disarming="handleDisarming"
              @referenceUpdate="handleReferenceUpdate"
              @logDownload="handleLogDownload"
              @autoCompetitorAdd="handleAutoCompetitorAdd"
              @notify="handleNotify"
            />
          </keep-alive>

          <BannerMessagesDialog
            :show="showBannerMessagesDialog"
            :event="event"
            @closed="showBannerMessagesDialog = false"
          />
        </div>
        <div v-else>
          <v-card-title>
            {{ $t(error) }}
          </v-card-title>
          <v-card-text>
            <i18n path="notFound.home">
              <template v-slot:action>
                <router-link :to="{ name: 'Home' }" exact>{{
                  $t("notFound.action")
                }}</router-link>
              </template>
            </i18n>
          </v-card-text>
        </div>
      </div>
      <v-card-text v-else></v-card-text>
      <v-snackbar-queue
        v-if="isOwner"
        :items="messages"
        bottom
        closeButtonIcon="mdi-close"
        :nextButtonCountText="$t('queue.nextCount')"
        :nextButtonText="$t('queue.next')"
        @remove="removeMessage"
      />
    </v-card>
  </v-container>
</template>

<script>
/* eslint-disable */

import cuid from "cuid";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import MarqueeText from "vue-marquee-text-component";
import TheOrganizer from "@/components/TheOrganizer";
import EventStateChip from "@/components/EventStateChip";
import EventViewersChip from "@/components/EventViewersChip";
import CountryFlag from "@/components/CountryFlag";
import BannerMessagesDialog from "@/components/BannerMessagesDialog";
import formatTimestamp_100ns from "@/mixins/formatTimestamp";

export default {
  name: "EventDetails",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    TheOrganizer,
    EventStateChip,
    EventViewersChip,
    CountryFlag,
    BannerMessagesDialog,
    MarqueeText
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      eventIsLoading: true,
      restartIsLoading: false,
      activeTab: null,
      messages: [],
      eventSubscription: null,
      eventDevices: null,
      error: null,
      showBannerMessagesDialog: false,
      pauseBanner: false,
      unsubscribeStore: null
    };
  },
  head() {
    return {
      link: [
        {
          rel: "canonical",
          href: `https://alge-results.com/event/${this.$route.params.id}/live`
        }
      ]
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      event: state => state.events.selectedItem,
      eventTrigger: state => state.events.selectedItemTrigger,
      triggerAutoUpdate: state => state.events.triggerAutoUpdate
    }),
    ...mapGetters({
      getCompetitorById: "events/getCompetitorById",
      getItemById: "nations/getItemById",
      getItemsByDeviceIds: "trigger/getItemsByDeviceIds",
      isConnected: "socket/isConnected"
    }),
    tabs() {
      return [
        {
          id: "overview",
          route: "Overview",
          name: this.$i18n.t("events.tabs.overview"),
          icon: "mdi-timetable"
        },
        {
          id: "competitors",
          route: "Competitors",
          name: this.$i18n.t("events.tabs.competitors"),
          icon: "mdi-account-group"
        },
        {
          id: "heats",
          route: "Heats",
          name: this.$i18n.t("events.tabs.heats"),
          icon: "mdi-run"
        },
        {
          id: "live",
          route: "Live",
          name: this.$i18n.t("events.tabs.live"),
          icon: "mdi-history"
        }
      ];
    },
    isOwner() {
      if (this.user && this.event) {
        return this.user.id === this.event.ownerId;
      }

      return false;
    },
    trigger() {
      if (this.event) {
        const deviceIds = this.event.setup.sections
          .map(section => section.devices)
          .flat()
          .map(device => device.deviceId);

        return this.getItemsByDeviceIds(deviceIds);
      }

      return [];
    },
    bannerAnimationSpeed() {
      if (this.event) {
        const totalCharacters = this.event.bannerMessages.reduce(
          (acc, curr) => {
            acc += curr.message.length;
            return acc;
          },
          0
        );
        const calculatedAnimationSpeed = Math.floor(totalCharacters / 5);
        return Math.max(1, calculatedAnimationSpeed);
      }

      return 15;
    }
  },
  watch: {
    isConnected(value) {
      if (value && this.event) {
        this.initEventSubscription();
      } else if (this.eventSubscription) {
        this.unsubscribe(this.eventSubscription);
      }
    }
  },
  mixins: [formatTimestamp_100ns],
  methods: {
    ...mapActions({
      fetchSelectedEvent: "events/fetchSelectedItem",
      restartItem: "events/restartItem",
      subscribe: "socket/subscribe",
      unsubscribe: "socket/unsubscribe",
      buildSelectedItemTriggerTimestampCollection:
        "events/buildSelectedItemTriggerTimestampCollection",
      buildSelectedItemHeatRunCollection:
        "events/buildSelectedItemHeatRunCollection"
    }),
    ...mapMutations({
      setSelectedItem: "events/setSelectedItem",
      addCompetitorToSelectedItem: "events/addCompetitorToSelectedItem",
      updateCompetitorOfSelectedItem: "events/updateCompetitorOfSelectedItem",
      setSelectedItemHeat: "events/setSelectedItemHeat",
      setRankingOfSelectedItemHeat: "events/setRankingOfSelectedItemHeat",
      setHeatCycleOfSelectedItemHeat: "events/setHeatCycleOfSelectedItemHeat",
      updateRunOfSelectedItemHeat: "events/updateRunOfSelectedItemHeat",
      removeRunOfSelectedItemHeat: "events/removeRunOfSelectedItemHeat",
      setRankingOfSelectedItem: "events/setRankingOfSelectedItem",
      addRunToSelectedItemHeat: "events/addRunToSelectedItemHeat",
      addRunsToSelectedItemHeats: "events/addRunsToSelectedItemHeats",
      updateTimingSetupOfSelectedItem: "events/updateTimingSetupOfSelectedItem",
      addCompetitorsToSelectedItem: "events/addCompetitorsToSelectedItem",
      addDocumentToSelectedItem: "events/addDocumentToSelectedItem",
      updateDocumentOfSelectedItem: "events/updateDocumentOfSelectedItem",
      removeDocumentFromSelectedItem: "events/removeDocumentFromSelectedItem",
      updateEventViewer: "events/updateViewerOfSelectedItem",
      addLogItem: "events/addLogItem",
      updateLogItem: "events/updateLogItem",
      removeLogItem: "events/removeLogItem",
      addTriggerToSelectedItemTrigger: "events/addTriggerToSelectedItemTrigger",
      addBannerMessageToSelectedItem: "events/addBannerMessageToSelectedItem",
      removeBannerMessageFromSelectedItem:
        "events/removeBannerMessageFromSelectedItem",
      addCompetitorInformation: "events/addCompetitorInformation",
      updateCompetitorInformation: "events/updateCompetitorInformation"
    }),
    getTabValue(route) {
      return this.$router.resolve({ name: route }).route.fullPath;
    },
    restartEvent() {
      if (confirm(this.$i18n.t("events.restartEventConfirmation"))) {
        this.restartIsLoading = true;

        this.restartItem(this.event.id)
          .then(() => {
            this.restartIsLoading = false;
          })
          .catch(() => {
            this.restartIsLoading = false;
          });
      }
    },
    handleSubscription(response) {
      if (process.env.NODE_ENV && process.env.NODE_ENV === "development") {
        console.log("TICK", response);
      }

      switch (response.entityType) {
        case "SportEventDetailDto":
          if (response.type === "ENTITY_UPDATE") {
            this.setSelectedItem(response.dto);
          }

          this.buildSelectedItemHeatRunCollection();

          if (this.isOwner) {
            this.buildSelectedItemTriggerTimestampCollection();
          }
          break;
        case "CompetitorDto":
          if (response.type === "ENTITY_ADD") {
            this.addCompetitorToSelectedItem(response.dto);
          } else if (response.type === "ENTITY_UPDATE") {
            this.updateCompetitorOfSelectedItem(response.dto);
          } else if (response.type === "BULK_ADD") {
            this.addCompetitorsToSelectedItem(response.dtos);
          }
          break;
        case "HeatDto":
          if (response.type === "ENTITY_UPDATE") {
            this.setSelectedItemHeat(response.dto);
          }

          this.buildSelectedItemHeatRunCollection();

          if (this.isOwner) {
            this.buildSelectedItemTriggerTimestampCollection();
          }
          break;
        case "HeatRankingDto":
          if (response.type === "ENTITY_UPDATE") {
            this.setRankingOfSelectedItemHeat(response.dto);
          }

          this.buildSelectedItemHeatRunCollection();
          break;
        case "HeatCycleDto":
          this.setHeatCycleOfSelectedItemHeat({
            heatId: response.dto.heatId,
            heatCycle: response.dto.entries
          });

          this.buildSelectedItemHeatRunCollection();

          if (this.isOwner) {
            this.buildSelectedItemTriggerTimestampCollection();
          }
          break;
        case "HeatRunDto":
          if (response.type === "ENTITY_ADD") {
            this.addRunToSelectedItemHeat({
              heatId: response.dto.heatId,
              run: response.dto
            });
          } else if (response.type === "BULK_ADD") {
            this.addRunsToSelectedItemHeats(response.dtos);
          } else if (response.type === "ENTITY_UPDATE") {
            this.updateRunOfSelectedItemHeat({
              heatId: response.dto.heatId,
              run: response.dto
            });
          } else if (response.type === "ENTITY_DELETE") {
            this.removeRunOfSelectedItemHeat({
              heatId: response.dto.heatId,
              run: response.dto
            });
          }

          this.buildSelectedItemHeatRunCollection();

          if (this.isOwner) {
            this.buildSelectedItemTriggerTimestampCollection();
          }
          break;
        case "EventRankingDto":
          this.setRankingOfSelectedItem(response.dto);
          break;
        case "TimingSetupDto":
          this.updateTimingSetupOfSelectedItem(response.dto);
          break;
        case "SportEventDocumentDto":
          if (response.type === "ENTITY_ADD") {
            this.addDocumentToSelectedItem(response.dto);
          } else if (response.type === "ENTITY_UPDATE") {
            this.updateDocumentOfSelectedItem(response.dto);
          } else if (response.type === "ENTITY_DELETE") {
            this.removeDocumentFromSelectedItem(response.dto);
          }
          break;
        case "EventLogMessageDto":
          switch (response.dto.type) {
            case "UNHANDLED_TRIGGER":
              this.addMessage(
                this.$i18n.t("events.notes.unhandledTrigger", {
                  startNumber: response.dto.payload.startNumber
                }),
                "warning"
              );
              break;
            case "INVALID_BIB":
              if (this.checkInvalidBib(response.dto)) {
                this.addMessage(
                  this.$i18n.t("events.notes.invalidBib", {
                    startNumber: response.dto.payload.startNumber
                  }),
                  "warning"
                );
              }
              break;
            case "SPEED_UNDERCUT":
              this.addMessage(
                this.$i18n.t("events.notes.speedUndercut"),
                "warning"
              );
              break;
            case "SPEED_EXCEED":
              this.addMessage(
                this.$i18n.t("events.notes.speedExceed"),
                "warning"
              );
              break;
            case "FILTER_TRIGGER_PREMATURE":
              this.addMessage(
                this.$i18n.t("events.notes.filterTriggerPremature"),
                "info"
              );
              break;
            case "FILTER_TRIGGER_DELAYED":
              this.addMessage(
                this.$i18n.t("events.notes.filterTriggerDelayed"),
                "info"
              );
              break;
            case "RESEND_TRIGGER_APPLIED":
              this.addMessage(
                this.$i18n.t("events.notes.resendTriggerApplied"),
                "info"
              );
              break;
            default:
              this.addMessage(response.dto.type, "warning");
              break;
          }
          break;
        case "EventViewerDto":
          if (response.type === "ENTITY_UPDATE") {
            this.updateEventViewer(response.dto.viewers);
          }
          break;
        case "EventLogDto":
          if (response.type === "ENTITY_ADD") {
            this.addLogItem(response.dto);
          } else if (response.type === "ENTITY_UPDATE") {
            this.updateLogItem(response.dto);
          } else if (response.type === "ENTITY_DELETE") {
            this.removeLogItem(response.dto);
          }
          break;
        case "SportEventBannerDto":
          if (response.type === "ENTITY_ADD") {
            this.addBannerMessageToSelectedItem(response.dto);
          } else if (response.type === "ENTITY_DELETE") {
            this.removeBannerMessageFromSelectedItem(response.dto);
          }
          break;
        case "HeatCompetitorInformationDto":
          if (response.type === "ENTITY_ADD") {
            this.addCompetitorInformation(response.dto);
          } else if (response.type === "ENTITY_UPDATE") {
            this.updateCompetitorInformation(response.dto);
          }
          break;
      }
    },
    addMessage(message, color) {
      this.messages.push({
        id: cuid(),
        message,
        color
      });
    },
    removeMessage(id) {
      const index = this.messages.findIndex(el => el.id === id);

      if (index !== -1) {
        this.messages.splice(index, 1);
      }
    },
    checkInvalidBib(invalidMessageDto) {
      const deviceId = invalidMessageDto.payload.deviceId;
      const heatId = invalidMessageDto.payload.heatId;
      const startNumber = invalidMessageDto.payload.startNumber;

      const startSection = this.event.setup.sections[0];
      const finishSection = this.event.setup.sections[
        this.event.setup.sections.length - 1
      ];

      const startHasDevice = startSection.devices.findIndex(
        device => device.deviceId === deviceId
      );
      const finishHasDevice = finishSection.devices.findIndex(
        device => device.deviceId === deviceId
      );

      if (startHasDevice !== -1 && finishHasDevice !== -1 && heatId) {
        const heatIndex = this.event.heats.findIndex(
          heat => heat.id === heatId
        );

        if (heatIndex !== -1) {
          for (const entry of this.event.heats[heatIndex].heatCycle.entries) {
            const competitor = this.getCompetitorById(entry.competitorId);

            if (competitor && competitor.startNumber === startNumber) {
              if (entry.state === "STARTED") {
                return false;
              } else {
                return true;
              }
            }
          }
        }
      }

      return true;
    },
    initEventSubscription() {
      return new Promise((resolve, reject) => {
        this.subscribe({
          topic: `/topic/sportevents/${this.event.id}/update`,
          callback: tick => {
            this.handleSubscription(JSON.parse(tick.body));
          }
        })
          .then(id => {
            this.eventSubscription = id;
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    handleArming(success, response) {
      const status = response.data.status;

      if (success && status === 0) {
        this.addMessage(this.$i18n.t("events.notes.arming.success"), "success");
      } else {
        switch (status) {
          case -3030:
            this.addMessage(
              this.$i18n.t("events.notes.arming.invalidState"),
              "error"
            );
            break;
          default:
            this.addMessage(
              this.$i18n.t("events.notes.arming.failed"),
              "error"
            );
        }
      }
    },
    handleDisarming(success, response) {
      const status = response.data.status;

      if (success && status === 0) {
        this.addMessage(
          this.$i18n.t("events.notes.disarming.success"),
          "success"
        );
      } else {
        switch (status) {
          case -3030:
            this.addMessage(
              this.$i18n.t("events.notes.disarming.invalidState"),
              "error"
            );
            break;
          default:
            this.addMessage(
              this.$i18n.t("events.notes.disarming.failed"),
              "error"
            );
        }
      }
    },
    handleNotify(success, response) {
      if (success) {
        const competitors = response.data.data.length;
        this.addMessage(
          this.$i18n.t("events.notes.notify.success", { competitors }),
          "success"
        );
      } else {
        this.addMessage(this.$i18n.t("events.notes.notify.failed"), "error");
      }
    },
    handleReferenceUpdate(success, response) {
      const status = response.data.status;

      if (success && status === 0) {
        this.addMessage(
          this.$i18n.t("events.notes.referenceUpdate.success"),
          "success"
        );
      } else {
        switch (status) {
          case -3011:
            this.addMessage(
              this.$i18n.t("events.notes.referenceUpdate.heatNotFound"),
              "error"
            );
            break;
          case -3026:
            this.addMessage(
              this.$i18n.t("events.notes.referenceUpdate.runNotFound"),
              "error"
            );
            break;
          case -3027:
            this.addMessage(
              this.$i18n.t("events.notes.referenceUpdate.runNotFinished"),
              "error"
            );
            break;
          case -4000:
            this.addMessage(
              this.$i18n.t("events.notes.referenceUpdate.tolerancesNotValid"),
              "error"
            );
            break;
          default:
            this.addMessage(
              this.$i18n.t("events.notes.referenceUpdate.failed"),
              "error"
            );
        }
      }
    },
    handleLogDownload(success) {
      if (success) {
        this.addMessage(
          this.$i18n.t("events.notes.logDownload.success"),
          "success"
        );
      } else {
        this.addMessage(
          this.$i18n.t("events.notes.logDownload.failed"),
          "error"
        );
      }
    },
    handleAutoCompetitorAdd(success, response) {
      const status = response.data.status;

      if (success && status === 0) {
        this.addMessage(
          this.$i18n.t("events.notes.autoCompetitorAdd.success"),
          "success"
        );
      } else {
        switch (status) {
          default:
            this.addMessage(
              this.$i18n.t("events.notes.autoCompetitorAdd.failed"),
              "error"
            );
        }
      }
    },
    fetchEvent() {
      return new Promise((resolve, reject) => {
        this.fetchSelectedEvent(this.$route.params.id)
          .then(() => {
            this.eventIsLoading = false;
            this.buildSelectedItemHeatRunCollection();
            resolve();
          })
          .catch(response => {
            switch (response.data.status) {
              case -3000:
                this.error = "events.errors.eventNotFound";
                break;
              default:
                this.error = "errors.unexpectedError";
            }

            this.eventIsLoading = false;
            reject();
          });
      });
    },
    setEventDevices() {
      this.eventDevices = new Set();

      this.event.setup.sections.map(section => {
        section.devices.map(device => {
          this.eventDevices.add(`${device.deviceId}-${device.channel}`);
        });
      });
    },
    initTriggerWatcher() {
      this.setEventDevices();
      this.unsubscribeStore = this.$store.subscribe(mutation => {
        if (this.triggerAutoUpdate && mutation.type === "trigger/addItem") {
          const trigger = mutation.payload;

          if (
            this.eventTrigger &&
            this.eventDevices &&
            this.eventDevices.has(
              `${trigger.deviceId}-${trigger.timingChannel}`
            )
          ) {
            this.addTriggerToSelectedItemTrigger(trigger);
          }
        }
      });
    }
  },
  created() {
    this.fetchEvent()
      .then(() => {
        this.initTriggerWatcher();

        if (this.isConnected && this.eventSubscription === null) {
          this.initEventSubscription();
        }
      })
      .catch(error => {
        console.log(error);
      });
  },
  beforeDestroy() {
    this.unsubscribe(this.eventSubscription);
    if (this.unsubscribeStore) {
      this.unsubscribeStore();
    }
  }
};
</script>
