<template>
  <v-card flat tile class="d-flex overline">
    <div v-if="organizer.imageUrl">
      <a
        v-if="organizer.organizerWebsite"
        :href="organizer.organizerWebsite"
        :title="organizer.organizerName"
        target="_blank"
      >
        <img
          :src="`${baseURL}/${organizer.imageUrl}`"
          style="max-width: 100%; max-height: 100px;"
        />
      </a>
      <img
        v-else
        :src="`${baseURL}/${organizer.imageUrl}`"
        style="max-width: 100%; max-height: 100px;"
      />
    </div>
    <div v-else class="d-flex align-center">
      <div v-if="organizer.organizerWebsite">
        {{ $t("organizer.organizedBy") }}
        <a
          :href="organizer.organizerWebsite"
          :title="organizer.organizerName"
          target="_blank"
          >{{ organizer.organizerName }}</a
        >
      </div>
      <div v-else>
        {{ $t("organizer.organizedBy") }} {{ organizer.organizerName }}
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "TheOrganizer",
  props: {
    organizer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL
    };
  }
};
</script>
