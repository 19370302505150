<template>
  <v-chip outlined>
    <v-avatar left>
      <v-icon>mdi-account-multiple</v-icon>
    </v-avatar>
    {{ viewersLabel }}
  </v-chip>
</template>

<script>
export default {
  name: "EventViewersChip",
  props: {
    viewers: {
      type: Number
    }
  },
  computed: {
    viewersLabel() {
      return this.viewers ? this.viewers : "-";
    }
  }
};
</script>
